
.vendorProductsList{
  // grid-template-columns: repeat(auto-fill, minmax(180px, 33%));
  @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(auto-fill, minmax(180px, 33%));

    // grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

  }

}